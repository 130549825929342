<template>
  <div class="comm-notice-post-container center">
    <div class="comm-notice-post">
      <hr>
      <div class="comm-notice-post-title">
        {{RECRUIT_POST.r_title}}
      </div>
      <div class="comm-notice-post-info">
        <span class="comm-notice-post-info-text">작성날짜 : {{changeDateFormat(RECRUIT_POST.created_at)}}</span>
      </div>
      <div class="comm-recruit-post-content">
        <p class="comm-recruit-post-title-text">구인정보</p>
        <table class="comm-recruit-post-table">
          <tr>
            <td>제목</td>
            <td>{{RECRUIT_POST.r_title}}</td>
          </tr>

          <tr>
            <td>모집인원</td>
            <td>{{RECRUIT_POST.r_1}}</td>
          </tr>

          <tr>
            <td>모집기간</td>
            <td>{{changeDateFormat(RECRUIT_POST.r_start)}} ~ {{changeDateFormat(RECRUIT_POST.r_end)}}</td>
          </tr>

          <tr>
            <td>급여조건</td>
            <td>{{RECRUIT_POST.r_2}}</td>
          </tr>

          <tr>
            <td>경력여부</td>
            <td>{{RECRUIT_POST.r_3}}</td>
          </tr>

          <tr>
            <td>고용형태</td>
            <td>{{RECRUIT_POST.r_4}}</td>
          </tr>

          <tr>
            <td>근무지역</td>
            <td>{{RECRUIT_POST.r_5}}</td>
          </tr>

          <tr>
            <td>연령</td>
            <td>{{RECRUIT_POST.r_6}}</td>
          </tr>

          <tr>
            <td>학력</td>
            <td>{{RECRUIT_POST.r_7}}</td>
          </tr>

          <tr>
            <td>직무내용</td>
            <td>{{RECRUIT_POST.r_8}}</td>
          </tr>

          <tr>
            <td>선발방법</td>
            <td>{{RECRUIT_POST.r_9}}</td>
          </tr>

          <tr>
            <td>구비서류 및 자격증</td>
            <td>{{RECRUIT_POST.r_10}}</td>
          </tr>

          <tr>
            <td>비고</td>
            <td>{{RECRUIT_POST.r_11}}</td>
          </tr>
        </table>
      </div>
      <hr>
      
      <div class="comm-recruit-post-content">
        <p class="comm-recruit-post-title-text">업체정보</p>
        <table class="comm-recruit-post-table">
          <tr>
            <td>회사명</td>
            <td>{{RECRUIT_POST.c_title}}</td>
          </tr>

          <tr>
            <td>전화번호</td>
            <td>{{RECRUIT_POST.c_1}}</td>
          </tr>

          <tr>
            <td>팩스번호</td>
            <td>{{RECRUIT_POST.c_2}}</td>
          </tr>

          <tr>
            <td>홈페이지</td>
            <td>{{RECRUIT_POST.c_3}}</td>
          </tr>

          <tr>
            <td>E-mail</td>
            <td>{{RECRUIT_POST.c_4}}</td>
          </tr>

          <tr>
            <td>사업자등록번호</td>
            <td>{{RECRUIT_POST.c_5}}</td>
          </tr>

          <tr>
            <td>대표자 성명</td>
            <td>{{RECRUIT_POST.c_6}}</td>
          </tr>

          <tr>
            <td>종업원수</td>
            <td>{{RECRUIT_POST.c_7}}</td>
          </tr>

          <tr>
            <td>자본금</td>
            <td>{{RECRUIT_POST.c_8}}</td>
          </tr>

          <tr>
            <td>주소</td>
            <td>{{RECRUIT_POST.c_9}}</td>
          </tr>

          <tr>
            <td>인사담당자</td>
            <td>{{RECRUIT_POST.c_10}}</td>
          </tr>

        </table>
      </div>

      <hr>


    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  created() {
    this.FETCH_RECRUIT_POST(this.$route.params.id)
  },

  methods : {
    ...mapActions([
      'FETCH_RECRUIT_POST'
    ]),

    changeDateFormat(date) {
        const d = new Date(date)
        let result = moment(d).format('YY-MM-DD')
        if(result == "Invalid date") return ''
        return result
    },
  },

  computed : {
    ...mapState([
      'RECRUIT_POST'
    ])
  }
}
</script>

<style>

</style>
